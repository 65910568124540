<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'salaryMasterView'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head max-width-900"
        role="document"
      >
        <div class="modal-content bg-white border-0">
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Salary Master-#{{ details.id}}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <!-- <div class="bill-head-icon mr-3">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-edit" @click="edit('newItem', details.id)"></i>
                </div> -->
                <div
                  class="bill-head-icon mr-1" title="Close"
                  @click="$store.dispatch('modalClose')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-md-4 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Employee</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{details.employee.name}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Month </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                              <span v-for="(month,index) in months" :key="index">
                                <span v-if="month.id == salary_month">: {{month.name}}</span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Attendence Information</label>
                      <div class="group-attribute-container" v-if="attendence">
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Total Month Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.total_days?attendence.total_days:0}}</div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Present Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.present?attendence.present:0}}</div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Holidays Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.holiday?attendence.holiday:0}}</div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Absent Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.absent?attendence.absent:0}}</div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Half Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.half?attendence.half:0}}</div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Late Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">: {{attendence.late?attendence.late:0}}</div>
                        </div>
                      </div>
                      <div class="group-attribute-container" v-else>
                        <div class="row mb-1">
                          <div class="col-md-12 ml-2 mr-0 pr-0">No Attendence Found</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Deduction</label>
                      <div class="group-attribute-container" v-for="(extra_field, index) in details.extra_fields"
                          :key="index">
                        <div class="row mb-2" 
                          v-if="extra_field.type == '1'"
                          >
                          <div class="col-md-5 mr-0 pr-0">
                            {{extra_field.title}}
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(extra_field.amount)}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Addition</label>
                      <div class="group-attribute-container" v-for="(extra_field, index) in details.extra_fields"
                          :key="index">
                        <div class="row mb-2" 
                          v-if="extra_field.type == '0'"
                          >
                          <div class="col-md-5 mr-0 pr-0">
                            {{extra_field.title}}
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(extra_field.amount)}}
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="group mb-3">
                      <label class="group-label">Taxes</label>
                      <div class="group-attribute-container" v-for="(tax_field, index) in details.tax_fields"
                          :key="index">
                        <div class="row mb-2" 
                          >
                          <div class="col-md-5 mr-0 pr-0">
                            {{tax_field.title}}
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(tax_field.amount)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Salary Report</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Basic Salary
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.employee_salary)}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Gross Salary
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.gross_salary)}}
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Net Salary
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.net_salary)}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Adv. Due
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.employee_advance)}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Adv. Deduct
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.advance_deduct)}}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Payable Salary
                          </div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{parseDigitForSlip(details.payable_salary)}}
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "../../../HumanResource/Services/Services";
import LargeMonths from "../../../../../store/constants/months";
export default {
  data() {
    return {
      details: [],
      attendence:{},
      err:'',
      months: LargeMonths.largeMonths,
    };
  },
  props:['salary_month','salary_year'],
  methods:{
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    // attendenceInfo(id) {
      
    //   let param = {
    //     month: this.salary_month,
    //     year: this.salary_year,
    //     id: id,
    //   };
    //   console.log(param);
    //   Services.getSalaryMasterEmployeeAttendence(param)
    //     .then((res) => {
    //       this.attendence = null,
    //       this.attendence = res.data.data;
    //       //this.$store.commit("setDataLists7", res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    attendenceInfo(id) {
      let param = {
        month: this.salary_month+1,
        year: this.salary_year,
        id: id,
      };
      Services.getSalaryMasterEmployeeAttendence(param)
        .then((res) => {
          this.attendence = res.data.data;
          //this.$store.commit("setDataLists7", res.data);
        })
        .catch((err) => {
         this.err = err;
        });
    },
  },
  computed: {
    ...mapGetters([
      "dataLists6",
      "dataLists7",
      "dataId",
      "modalId",
      "modalMode",
    ]),
  },
  watch: {
    modalId() {
      let id = this.dataId;
      if(this.modalId == 'salaryMasterView'){
        let datas = this.dataLists6.find(function (data) {
          return data.employee_id == id;
        });
        this.details = {...datas};
        this.attendenceInfo(this.details.employee_id);
        // this.details.extra_fields = this.details.extra_fields;
        // this.details.tax_fields = this.details.tax_fields;
      }
    },
  }
};
</script>

<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Salary</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Assignment Transaction Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Salary Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" class="mr-2 mg-t-5" @change="getFilteredDataWithoutDates">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="mr-2 mg-t-5" v-model="params.session_year" >
            <option v-for="(year,index) in sessionYears" :key="index" :value="year">{{year}}/{{parseInt(year.substring(year.length-2)) + 1}}</option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
            :show-day="false"
          ></SearchDayMonthComponent>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in salaryDataAtrributes.branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <input
            type="text"
            v-model="params.searched"
            class="filter-input mr-2 mg-t-5"
            placeholder="Search..."
            @keyup="searchFilter"
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/salary-report">Employee Wise</option>
              <option value="/general-report/salary-report/payroll-wise">Payroll Wise</option>
              <option value="/general-report/salary-report/transaction-wise">Transaction Wise</option>
              <option value="/general-report/salary-report/month-wise">Month Wise</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
       <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Gross Salary: {{salaryDataAtrributes.total_gross}}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Net Salary: {{salaryDataAtrributes.total_net}} </span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-10p">Employee ID</th>
              <th class="wd-15p">Employee</th>
              <th class="wd-10p">Designation</th>
              <th class="wd-10p">Gross Salary</th>
              <th class="wd-10p">Tax</th>
              <th class="wd-10p">Net Salary</th>
              <th class="wd-10p"> Advance Deduction</th>
              <th class="wd-10p table-end-item">Payable Salary</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && transactionWiseDatas.length > 0">
          <tr v-for="(salary,index) in transactionWiseDatas" :key="index">
            <th scope="row" class="table-start-item">{{pageSerialNo+index}}</th>
              <td>{{salary.employee.employee_id}}</td>
              <td>{{salary.employee.name}}</td>
              <td>{{salary.employee.designation}}</td>
              <td>{{parseDigitForSlip(salary.gross_salary)}}</td>
              <td>
                <div v-for="(tax) in salary.tax_fields" :key="tax.slug">
                  <span>{{tax.title}}:</span><span>{{parseDigitForSlip(tax.amount)}}</span>
                  <br>
                </div>
              </td>
              <td>{{parseDigitForSlip(salary.net_salary)}}</td>
              <td>{{parseDigitForSlip(salary.advance_deduct)}}</td>
              <td>{{parseDigitForSlip(salary.payable_salary)}}</td>
              <td>
                   <a
                title="View Salary"
                  href="javascript:;"
                  v-if="checkSinglePermission('view_salary_report')"
                  class="mr-3"
                >
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('salaryMasterView', salary.employee_id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" class="text-center">
              {{error_message}}
            </td>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" class="text-center">
                Loading...
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && transactionWiseDatas.length == 0">
            <tr>
              <td colspan="11" class="text-center">
                No Records Found...
              </td>
            </tr>
          </tbody>
        </table>
         <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
      </div>
      <Slip :salary_month="params.month" :salary_year="params.year"></Slip>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import Slip from "./Partials/SalaryMasterSlip";
// import Slip from "../../HumanResource/SalaryMaster/Slip.vue";
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
import _ from 'lodash';
export default {
  components: {
    Paginate,Slip,SearchDayMonthComponent
  },
   data(){
    return{
        sessionYears:['2021'],
        route:this.$route.path,
        params:{
          pageIndex:10,
          session_year:'2021',
          account:"",
          branch:"",
          searched:"",  
          offset:0,
          month:new Date().getMonth(),
          year:new Date().getFullYear(),
        },
        excel_params:{
          pageIndex:10,
          session_year:'2021',
          account:"",
          branch:"",
          searched:"",  
          offset:0,
          month:new Date().getMonth(),
          year:new Date().getFullYear(),
        },
        totalPageCount:0,
        page:1,
        pageSerialNo:1,
        loading:false,
        error:false,
        error_message:"",
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists6",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("salaryReports",['transactionWiseDatas','salaryDataAtrributes'])
  },
  methods: {
     filterReportType() {
      this.$router.push(this.route);
    },
     monthDayClicked(value){
      if(value){
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year
      }
      //make api call
      this.getFilteredData();
    },
     clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.offset = (page-1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    getFilteredData()
    {
    this.loading = true;
    this.params.from='';
    this.params.to="";
    let params ={
        'pageIndex': this.params.pageIndex,
        'branch' : this.params.branch,
        'today':this.params.day,
        'month':this.params.month + 1,
        'year':this.params.year,
        'searched' : this.params.searched,
        'session_years':this.params.session_year,
        'offset':this.params.offset,
      }
      this.excel_params ={
        'pageIndex': this.params.pageIndex,
        'branch' : this.params.branch,
        'today':this.params.day,
        'month':this.params.month + 1,
        'year':this.params.year,
        'searched' : this.params.searched,
        'session_years':this.params.session_year,
        'offset':this.params.offset,
      }
      this.getData(params);
    },
    // without month component filter
    getFilteredDataWithoutDates(){
     this.loading = true;
      let params ={
          'pageIndex': this.params.pageIndex,
          'branch' : this.params.branch,
          'today':'',
          'month':'',
          'year':'',
          'searched' : this.params.searched,
          'session_years':this.params.session_year,
          'offset':this.params.offset,
        }
        this.excel_params ={
          'pageIndex': this.params.pageIndex,
          'branch' : this.params.branch,
          'today':'',
          'month':'',
          'year':'',
          'searched' : this.params.searched,
          'session_years':this.params.session_year,
          'offset':this.params.offset,
        }
    this.getData(params);
  },
  searchFilter:_.debounce(function(){
        this.page=1;
        this.pageSerialNo=1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
    },1000),
    getData(param) {
      this.loading =true;
      Services.getTransactionWiseReport(param).then(res=>{
        this.$store.commit("salaryReports/setTransactionWiseDatas",res.data.data.data);
        this.$store.commit("salaryReports/setSalaryDataAttributes",res.data.data.attributes);
        this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
        this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
        this.$store.commit("salaryReports/setTransactionWiseDatas", []);
      }).finally(()=>{
        this.loading=false;
      });
    },
    print(){
      window.print();
    },
    view(modal, id) {
      this.$store.commit("setDataLists6", this.transactionWiseDatas);
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "view" });
    },
    download() {
      Services.getTransactionWiseExcel(this.excel_params)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "salary-trnasaction-wise-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
  },
};
</script>